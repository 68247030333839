.request {
    background-color: rgb(60, 16, 83) !important;
    color: #DF6589 !important;
  }
  .left-align {
    text-align: left;
    font-size: 32px;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
}
 a{
  font-family: 'karla' ,sans-serif;
}
.navigation{
  margin-bottom: 0;
  list-style-type: none;
    background: #df6589ff;
    position: relative;
    margin-right: 27px;
    padding-left: 38px;
    width: 846px;
    float: right;
    margin-top: 24px;
    font-size: 0;

}