

.bannercontainer {
    position: relative;
    width: 100%;
    height: 100vh; /* Set height to 100% of viewport height */
    margin: auto;
    overflow: hidden;
    
  }
  
  .banner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .banner img {
    width: 100vw; /* Set width to 100% of viewport width */
    height: 100vh; /* Set height to 100% of viewport height */
    object-fit: cover; /* Ensures the image covers the area while maintaining its aspect ratio */
  }
  
  .banner-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    z-index: 999;
}
.banner-text h2 {
  font-weight: 900;
  letter-spacing: -0.9px;
  white-space: normal;
  color: #fff;
  font-size: 48px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
  
  .big_white {
    color: white;
    font-size: 24px;
  }
  
  .big_orange {
    color: orange;
    font-size: 30px;
  }
  
  .medium_grey {
    color: grey;
    font-size: 18px;
  }
  
  .shipping, .services-link {
    text-decoration: none;
    color: inherit;
  }
  /* LogisticsService.css */

.section {
    padding: 60px 0;
  }
  
  .heading h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .servicespan {
    font-size: 16px;
    color: #666;
  }
  
  .slides-tab {
    margin-bottom: 30px;
  }
  
  .slides-tab img {
    width: 100%;
    height: auto;
  }
  
  .slides-text h4 {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .slides-text p {
    font-size: 14px;
    color: #666;
  }
  
  .read-more {
    color: #104c91;
    text-decoration: none;
  }
  
  .features-tab {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .features-tab i {
    font-size: 30px;
    color: #104c91;
  }
  
  .features-tab h5 {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .features-text {
    margin-bottom: 20px;
  }
  
  .about-us {
    padding: 60px 0;
  }
  
  .testimonial-section {
    padding: 30px;
    background-color: #f8f8f8;
    margin-top: 30px;
  }
  
  .custom-blockquote {
    font-size: 14px;
    color: #333;
  }
  
  .query {
    padding: 60px 0;
    background-color: #104c91;
    color: #fff;
    text-align: center;
  }
  .about {
    overflow: hidden;
    position: relative;
}
.parallax {
  background-size: cover;
}
  .figure {
    position: absolute;
    height: 100%;
    float: left;
    background: url(../../../public/assests/images/train.jpg) no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: calc(50% - 31px);
    background-position: left -105.2px;
    display: block !important;
}
.transport-king {
  width: 50%;
  width: calc(50% + 31px);
  float: right;
  position: relative;
}
.about-us {
  background: #104c91;
  padding: 80px 0px 125px 89px;
}
.description {
  max-width: 515px;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.slides-text h4{
  font-weight: 900;
  color: #2c3740;
  font-size: 24px;
  a{
    text-decoration: none;
  }
}